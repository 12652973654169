<template>
  <div v-if="fetchMixin.state === fetchMixin.STATES.LOADING"></div>
  <div v-else>
    <v-card flat tile :loading="fetchMixin.state === fetchMixin.STATES.LOADING">
      <v-card-title class="text-h4">
        <v-row no-gutters>
          <v-col>
            {{ $t('pages.choice_collection_info.title') }} #{{ id }}
          </v-col>
          <v-btn
            text color="primary"
            @click="$router.push('/choiceCollections')"
          >
            {{ $t('common.action.back') }}
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <choice-collection-form v-if="fetchMixin.state === fetchMixin.STATES.IDLE" :value="collection" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ChoiceCollectionForm from '@/components/choicecollection/ChoiceCollectionSaveForm.vue';
import FetchMixin from '@/mixins/fech.mixin';
import ChoiceCollectionService from '@/services/api/choice-collection.service';

export default {
  name: 'choice-collection-info',
  components: { ChoiceCollectionForm },
  mixins: [FetchMixin],
  props: {
    collectionProp: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    collection: {},
  }),
  computed: {
    id() {
      return this.$route.params.collectionId;
    },
    choicesEmpty() {
      return true;
    },
  },
  methods: {
    fetchChoiceCollection(collectionId) {
      return ChoiceCollectionService.fetchChoiceCollection(collectionId);
    },
  },
  created() {
    this.collection = { ...this.collectionProp };

    this.fetchMixin.config = {
      fetchById: 'fetchChoiceCollection',
      dataFieldOne: 'collection',
    };
    this.fetchById(this.id);
  },
};
</script>
